@tailwind base;
@tailwind components;
@tailwind utilities;

.hover-btn:hover {
  filter: brightness(95%);
}

.hover-btn:disabled,
.hover-btn[disabled] {
  filter: opacity(0.45);
  cursor: not-allowed;
}
